@import "src/assets/styles/vars";
.cartCheck {
  svg {
    visibility: hidden;
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    padding-bottom: 10px;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #000;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  h2 {
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
  }

  .btnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .delete {
      background-color: $delete-color;
      margin-right: 20px;

      &:hover {
        background-color: $delete-color-hover;
      }
    }
  }
}
