.recovery {
  h2 {
    text-align: center;
    font-size: 20px;
  }

  p {
    font-size: 16px;
    margin: 10px 0;
  }

  div {
    margin-bottom: 20px;
  }

  .btnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
}
