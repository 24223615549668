@import "src/assets/styles/vars";

.search {
  grid-area: search;
  display: grid;
  grid-template-columns: 1fr 54px;
  width: 100%;

  input {
    width: 100%;
    background-color: #FFF;
    border: 1px solid #ACACAC;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    padding: 14px 12px;
    transition: .1s ease-in-out;
    //height: 50px;
    @media (max-width: 1024px) {
      font-size: 14px;
    }

    @media (max-width: 960px) {
      font-size: 12px;
    }

    &::placeholder {
      color: #757575;
    }

    &:focus {
      border-color: #000;
    }
  }

  .subtitle {
    display: inline-flex;
    align-items: center;
    margin-left: 14px;
    cursor: pointer;
    //max-width: 145px;
    user-select: none;

    &:hover span {
      color: darken(#5A5A5A, 100%);
      border-bottom-color: darken(#5A5A5A, 100%);
    }

    span {
      width: fit-content;
      font-size: 12px;
      font-weight: 400;
      color: #5A5A5A;
      border-bottom: 1px dashed #5A5A5A;
      transition: .1s ease-in-out;
    }

    svg {
      fill: #5A5A5A;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border-radius: 0 5px 5px 0;
    background-color: $base-color;
    transition: .1s ease-in-out;

    &:hover {
      background-color: $base-color-darken;
    }
  }
}
