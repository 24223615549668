@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import "src/assets/styles/vars";
@import "src/assets/styles/mixins";

* {
  padding: 0;
  margin: 0;
  border: 0
}

*,
:after,
:before {
  box-sizing: border-box
}

:active,
:focus {
  outline: 0
}

a:active,
a:focus {
  outline: 0
}

aside,
footer,
header,
nav {
  display: block
}

body,
html {
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

button,
input,
textarea {
  font-family: "Montserrat", sans-serif;

  &::placeholder {
    color: #757575;
  }
}

input::-ms-clear {
  display: none
}

button {
  cursor: pointer
}

button::-moz-focus-inner {
  padding: 0;
  border: 0
}

a,
a:visited {
  text-decoration: none;
  color: #000;
}

a:hover {
  text-decoration: none
}

ul li {
  list-style: none
}

img {
  vertical-align: top
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit
}


body,
html {
  overflow-x: hidden;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #EDEDED;
  font-size: 14px;
  scrollbar-width: thin;
  //scrollbar-color: #B7C3CE #DAE1E8;
  scrollbar-face-color: #B7C3CE;
  scrollbar-track-color: #DAE1E8;
  scrollbar-shadow-color: #B7C3CE;
  scrollbar-highlight-color: #DAE1E8;
  scrollbar-3dlight-color: #DAE1E8;
  scrollbar-darkshadow-color: #B7C3CE;
  scrollbar-arrow-color: #B7C3CE;
}

.container {
  max-width: 1340px;
  padding: 0 30px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #DAE1E8;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #B7C3CE;
}

.btn {
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 45px;
  background-color: $base-color;
  border-radius: 4px;
  transition: .1s ease-in-out;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: $base-color-darken;
  }

  &.delete {
    background-color: $delete-color;

    &:hover {
      background-color: $delete-color-hover;
    }
  }

  &.cancel {
    background-color: #fff;
    color: $base-color;
    border: 2px solid $base-color;
    padding: 9px 45px;

    &:hover {
      color: $base-color-darken;
      background-color: darken(#fff, 10%);
    }
  }
}


h1.title {
  font-size: 40px;
  color: #000;
  margin: 35px 0 40px 0;
  @include fluid-font(24px, 40px, 400px, 1100px);

  &-admin {
    font-size: 26px;
    font-weight: 500;
    margin: 0 0 20px 0;
  }
}

h2.subtitle {
  &-admin {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

#root {
  overflow: hidden;
}
