.hoverInfo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 100%;

  &:hover .hoverText {
    opacity: 1;
    visibility: visible;
  }

  &.copy .hoverText {
    cursor: copy;
    user-select: none;
  }

  .hoverText {
    position: absolute;
    width: max-content;
    height: max-content;
    background-color: #FFF;
    padding: 5px;
    border-radius: 5px;
    color: #000;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 50%);
    transition: .1s ease-in-out;
    opacity: 0;
    visibility: hidden;
    cursor: text;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 15px;
    top: 0;
    bottom: 0;

    &.left {
      right: 15px;
      left: unset;
    }

    &.center {
      left: 0;
      right: 0;
    }
  }
}
