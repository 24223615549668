@import "src/assets/styles/mixins";
@import "src/assets/styles/vars";

.header {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  padding: 0 0 8px 0;

  .currencies {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 600;
  }

  nav {
    background-color: #E0E0E0;
    padding: 10px 0;

    :global {
      .container {
        display: flex;
        justify-content: space-between;
      }
    }

    .linksList {
      display: flex;

      align-items: center;

      li {
        margin-right: 20px;
        line-height: 15px;

        @media (max-width: 1120px) {
          margin-right: 10px;
        }

        &:last-child {
          margin-right: 0;
        }

        a, div {
          color: #000;
          position: relative;
          transition: .1s ease-in-out;
          cursor: pointer;

          @media (max-width: 1200px) {
            font-size: 13px;
          }

          @media (max-width: 1120px) {
            font-size: 12px;
          }


          &:hover {
            color: $base-color;

            &:after {
              opacity: 1;
            }
          }

          &:after {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background-color: $base-color;
            opacity: 0;
            transition: .1s ease-in-out;
          }

          &.active {
            color: $base-color;

            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    .selectList {
      display: flex;

      & > div {
        &:first-child {
          border-right: 0;
        }
      }
    }
  }

  &Container {
    display: grid;
    grid-template-columns: 200px 1fr max-content max-content;
    //grid-template-rows: 50px 13px;
    //grid-template-areas: "logo search btn btn"
    //                     ". search . .";
    grid-template-areas: "logo search btn btn";
    gap: 0 35px;
    max-width: 1300px;
    padding: 0 50px;
    margin: 15px auto 0 auto;

    @media (max-width: 1200px) {
      gap: 0 20px;
      grid-template-columns: 150px 1fr max-content max-content;
      padding: 0 25px;
    }

    .logo {
      grid-area: logo;
      align-self: center;

      a {
        display: flex;

        svg {
          width: 100%;
          height: 50px;
        }
      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
        @media (max-width: 910px) {
          display: none;
        }
      }

      a.active {
        color: $base-color;
        border-color: $base-color;

        svg {
          stroke: $base-color;
        }
      }

      .cartBtn {
        position: relative;

        &:after {
          content: attr(data-count);
          position: absolute;
          right: -10px;
          top: -10px;
          width: 25px;
          height: 25px;
          padding: 5px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: $base-color;
          border-radius: 50%;
          color: #fff;
        }
      }

      a, div {
        display: flex;
        align-items: center;
        transition: .1s ease-in-out;
        border: 1px solid #000;
        padding: 8px;
        border-radius: 5px;
      }

      &:hover {
        a, div {
          color: $base-color;
          border-color: $base-color;
        }

        svg {
          stroke: $base-color;
        }
      }

      svg {
        transition: .1s ease-in-out;
        margin-right: 10px;
        @media (max-width: 910px) {
          margin: 0;
        }
      }
    }
  }
}
