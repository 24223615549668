@use 'sass:math';

@mixin fluid-font($min-size, $max-size, $min-viewport, $max-viewport) {
  $viewport-range: $max-viewport - $min-viewport;
  $size-range: $max-size - $min-size;

  font-size: $min-size;

  @media (min-width: $min-viewport) {
    font-size: calc(#{$min-size} + #{math.div($size-range, $viewport-range) * 100vw});
  }

  @media (min-width: $max-viewport) {
    font-size: $max-size;
  }
}
