.orderTable {
  padding-top: 30px;
  @media (max-width: 1300px) {
    padding: 30px 30px 0;
  }
  @media (max-width: 500px) {
    padding: 15px 0 0;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    @media (max-width: 500px) {
      padding-left: 15px;
    }
  }

  .orderItems {
    height: 340px;
    border-radius: 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
      overflow-x: scroll;
      max-width: calc(100vw - 120px);
    }

    @media (max-width: 768px) {
      max-width: calc(100vw - 90px);
    }

    @media (max-width: 500px) {
      max-width: calc(100vw - 32px);
    }

    & > div {
      padding-right: 0;

      &:first-child {
        grid-template-columns: 250px 150px 100px 80px;
        @media (max-width: 1300px) {
          grid-template-columns: 1fr 150px 100px 80px;
        }
        @media (max-width: 1000px) {
          width: 1100px;
        }
        border-radius: 0;
      }
    }

    .itemsBlock {
      display: block;
      max-height: 244px;
      overflow-x: hidden;
      overflow-y: auto;
      gap: 0;
      padding: 0;
      flex: 1 1 0;

      @media (max-width: 1000px) {
        width: 1100px;
      }

      & > div {
        display: grid;
        grid-template-columns: 250px 150px 100px 80px;
        align-items: center;
        text-align: center;
        gap: 20px;
        padding: 10px 20px;
        line-height: 20px;
        transition: 0.1s ease-in-out;

        @media (max-width: 1300px) {
          grid-template-columns: 1fr 150px 100px 80px;
        }

        & > div:first-child {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 250px;
          height: 1.2em;
          white-space: nowrap;
          text-align: left;
          @media (max-width: 1300px) {
            width: 100%;
          }
        }
      }
    }

    .total {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
      background-color: #fff !important;

      @media (max-width: 1000px) {
        position: sticky;
        width: 300px;
        left: calc(100% - 300px);
      }

      //@media (max-width: 768px) {
      //  left: calc(50vw - 90px);
      //}

      span {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}
