@import "src/assets/styles/vars";

.sidebar {
  grid-area: sidebar;
  position: fixed;
  left: 0;
  top: 0;
  width: 60px;
  height: 100vh;
  z-index: 2;
  overflow: hidden;
  transition: .2s ease-in-out;

  @media (max-height: 900px) {
    ::-webkit-scrollbar {
      width: 0;
      transition: .2s ease-in-out;
    }
    scrollbar-width: none;
    ul {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    &:hover {
      scrollbar-width: thin;

      ::-webkit-scrollbar {
        width: 5px;
      }
    }
  }

  &:hover {
    width: 260px;
  }

  ul {
    width: 100%;
    height: 100%;
    background: $base-color;
    z-index: 1;
    color: #fff;
    padding: 50px 0;


    li {
      font-size: 18px;
      margin-bottom: 30px;
      width: 260px;

      a {
        display: grid;
        grid-template-columns: 45px 1fr;
        align-items: center;
        color: #000;
        width: max-content;
        cursor: pointer;
        margin-left: 15px;


        &.active {
          svg {
            &.linksStroke {
              stroke: #fff;
              fill: none;
            }

            fill: #fff;
          }

          span {
            color: #fff;
          }
        }

        &:hover {
          svg {
            &.linksStroke {
              stroke: #fff;
              fill: none;
            }

            fill: #fff;
          }

          span {
            color: #fff;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        justify-self: center;
        width: 30px;
        height: 30px;
        margin: 0 15px 0 0;
        transition: .1s ease-in-out;
      }

      span {
        transition: .1s ease-in-out;
      }
    }
  }
}
