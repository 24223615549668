@import "src/assets/styles/vars";

.notification {
  position: relative;

  &Icon {
    display: inline-block;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;

    &:hover {
      svg {
        fill: $base-color-darken;
      }
    }

    svg {
      transition: .1s ease-in-out;
    }

    span {
      transition: .1s ease-in-out;
      position: absolute;
      top: 0;
      right: 0;
      padding: 3px;
      font-weight: 500;
      background-color: $base-color;
      border-radius: 50%;
      width: 20px;
      aspect-ratio: 1/1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }

  &Menu {
    padding: 15px;
    position: absolute;
    right: -180px;
    width: 400px;
    height: 300px;
    top: 44px;
    background-color: #fff;
    border-radius: 10px 0 0 10px;
    z-index: 99;
    border: 1px solid $base-color;
    overflow-y: auto;

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 10px solid $base-color;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      h3 {
        text-transform: uppercase;
        font-weight: bold;
        color: $base-color;
      }

      span {
        color: grey;
        cursor: pointer;
        transition: .1s ease-in-out;
        border-bottom: 1px solid transparent;

        &:hover {
          color: $base-color-hover;
          border-bottom-color: $base-color-hover;
        }
      }
    }

    .item {
      display: block;
      background: #e6eaf5;
      padding: 10px;
      font-size: 16px;
      border-radius: 5px;
      margin-bottom: 10px;
      transition: .1s ease-in-out;

      cursor: pointer;

      &:hover {
        background-color: $base-color-hover;
      }

      &:last-child {
        margin-bottom: 0;
      }

      span:last-child:not(.noLink) {
        margin-left: 5px;
        font-weight: bold;
        color: $base-color;
        border-bottom: 1px solid $base-color;

      }
    }

  }
}
