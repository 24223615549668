@import "src/assets/styles/vars";
@import "src/assets/styles/mixins";

.checkout {
  .tabs {
    .tabList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 1400px) {
        flex-wrap: wrap;
      }

      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        font-size: 16px;
        border: 1px solid $base-color;
        border-right-color: transparent;
        width: 33.33%;
        //width: 25%;
        transition: .1s ease-in-out;
        position: relative;
        cursor: default;
        user-select: none;

        @media (max-width: 1400px) {
          width: 50%;

          &:nth-child(2) {
            border-radius: 0 5px 0 0;
            border-right: 1px solid $base-color;
            border-bottom: none;
          }
        }

        @media (max-width: 680px) {
          width: 100%;
          &:nth-child(2) {
            border-radius: 0;
          }
          &:nth-child(3) {
            border-right: 1px solid $base-color;
            border-bottom: none;
          }
        }
        @media (max-width: 400px) {
          font-size: 14px;
        }

        &:first-child {
          border-radius: 5px 0 0 0;
          @media (max-width: 1400px) {
            border-bottom: none;
          }
          @media (max-width: 680px) {
            border-radius: 5px 5px 0 0;
            border-right: 1px solid $base-color;
          }
        }

        &:last-child {
          border-radius: 0 5px 0 0;
          @media (max-width: 1400px) {
            border-radius: 0;
          }
        }

        &:before {
          content: attr(data-index);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 20px;
          margin: auto 0;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $base-color;
          border-radius: 50%;
        }

        &.selected {
          background-color: $base-color;
          color: #fff;

          &:before {
            border-color: #fff;
          }
        }

        &.done {
          background-color: darken(#fff, 15%);
          cursor: pointer;

          &:before {
            content: '';
            border: 0;
            background: url("../../../assets/svg/verify.svg") center no-repeat;
          }
        }

        &:last-child {
          border-right-color: $base-color;
        }
      }
    }

    .tabPanel {
      display: none;

      border: 1px solid $base-color;
      border-top: 0;
      border-radius: 0 0 5px 5px;

      .selectError {
        color: $error-color;
        margin-left: 17px;
      }

      .radioError {
        color: $error-color;
      }

      .select {
        background-color: #fff;
        border: 1px solid transparent;
        max-width: unset;

        &.invalid {
          margin-bottom: 10px;

          & div:first-child {
            border: 1px solid $error-color;
          }
        }

        &Region {
          overflow-y: scroll;
        }
      }

      form {
        display: flex;
        flex-direction: column;
      }

      &Content {
        grid-area: content;
        padding: 30px 30px 0 30px;
        flex: 1 1 0;

        @media (max-width: 400px) {
          padding: 15px 15px 0 15px;
        }

        &.personalData {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-areas: "title choices" "first choices" "second ." "third ." "fourth .";
          gap: 0 50px;


          @media (max-width: 1000px) {
            grid-template-areas: "title title"
                                "first first"
                                "second second"
                                "third third"
                                "fourth fourth";
            gap: 0;

            &.add {
              grid-template-areas: "choices choices"
                                "title title"
                                "first first"
                                "second second"
                                "third third"
                                "fourth fourth";
            }
          }

          @media (max-width: 520px) {

          }

          h2 {
            grid-area: title;
          }

          .flex {
            grid-area: first;
            width: 100%;
          }

          .formBlock {
            max-width: 100%;

            &:nth-child(3) {
              grid-area: second;
            }

            &:nth-child(4) {
              grid-area: third;
            }

            &:nth-child(5) {
              grid-area: fourth;
            }
          }

          .choices {
            grid-area: choices;
          }
        }

        h2 {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 24px;

          @media (max-width: 400px) {
            font-size: 16px;
          }
        }

        .userBlock {
          display: flex;
          margin-bottom: 15px;

          li {
            border-radius: 10px;
            padding: 10px;
            box-shadow: 0 1px 3px rgb(54 74 178 / 16%), 0 2px 2px rgb(54 74 178 / 4%), 0 0 2px rgb(54 74 179 / 8%);
            cursor: pointer;
            transition: .1s ease-in-out;

            &:hover {
              background-color: darken(#fff, 10%);
            }

            &.selected {
              box-shadow: none;
              background-color: #EDEDED;
              cursor: default;

            }

            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }

      &.selected {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "content";
        height: 530px;
        position: relative;

        @media (max-width: 1000px) {
          height: auto;
          margin-bottom: 20px;
        }

        &:last-child {
          display: grid;
          grid-template-rows: 1fr 106px;
          grid-template-areas: "content order"
                             "btns btns";
          @media (max-width: 1300px) {
            height: auto;
            margin-bottom: 20px;
            grid-template-rows: unset;
            grid-template-areas: "order order"
                              "content content"
                             "btns btns";
          }
        }
      }

      .btnWrapper {
        grid-area: btns;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 32px;
        align-self: flex-end;

        @media (max-width: 500px) {
          padding: 15px;
        }

        @media (max-width: 420px) {
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          width: 100%;

          button {
            width: 100%;
            font-size: 14px;

            &:last-child {
              margin-top: 20px;
            }
          }
        }

        :global {
          .cancel {
            margin-right: 10px;
            @media (max-width: 420px) {
              margin: 0;
            }
          }
        }
      }
    }

    .flex {
      display: flex;
      align-items: center;
      width: 50%;

      @media (max-width: 520px) {
        flex-direction: column;
      }

      &.invalid .formBlock input {
        margin-bottom: 20px;
      }


      & .formBlock {
        width: 100%;

        &:first-child {
          margin-right: 10px;
          @media (max-width: 520px) {
            margin: 0 0 10px 0;
          }
        }
      }
    }

    .formBlock {
      font-size: 16px;
      max-width: 50%;
      margin-bottom: 10px;
      position: relative;
      @media (max-width: 400px) {
        font-size: 14px;
      }
      @media (max-width: 850px) {
        max-width: 100%;
      }

      &.w100 {
        max-width: 100%;
      }

      label {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-bottom: 5px;
        @media (max-width: 400px) {
          font-size: 14px;
        }
      }

      .input {
        border: 1px solid transparent;
        box-shadow: 0 0 4px rgb(0 0 0 / 20%);
        height: 56px;
        line-height: 24px;
        padding: 18px 16px;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #333;
        border-radius: 4px;
        width: 100%;
        transition: border-color 0.1s ease-in-out;

        @media (max-width: 400px) {
          font-size: 14px;
        }

        &.invalid {
          border-color: $error-color;
          margin-bottom: 20px;
        }

        &:focus {
          border-color: $base-color;
        }
      }

      .textarea {
        height: 150px;
        resize: none;
        @media (max-width: 13000px) {
          max-width: 600px;
        }
      }

      span.error {
        color: $error-color;
        position: absolute;
        bottom: 0;
        left: 17px;
      }
    }

    .confirmBlock {
      display: flex;
      margin-bottom: 20px;

      @media (max-width: 575px) {
        flex-direction: column;
      }


      span {
        display: block;
        font-size: 16px;

        &:first-child {
          width: 30%;
          @media (max-width: 575px) {
            width: 100%;
            margin-bottom: 5px;
          }
        }

        &:last-child {
          width: 70%;
          text-align: left;
          font-weight: 500;

          @media (max-width: 575px) {
            width: 100%;
          }
        }
      }
    }

    .blockTitle {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: 5px;
      font-size: 16px;
    }

    .radioBlock {
      .radio {
        padding: 6px;
        border-radius: 50px;
        display: inline-flex;
        cursor: pointer;
        transition: background .1s ease-in-out;
        -webkit-tap-highlight-color: transparent;

        &:hover,
        &:focus-within {
          background: rgb(0 0 0 / 10%);
        }

        input {
          vertical-align: middle;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          background: none;
          border: 0;
          box-shadow: inset 0 0 0 1.5px rgb(0 0 0 / 20%);
          appearance: none;
          padding: 0;
          margin: 0;
          transition: box-shadow .1s cubic-bezier(.95, .15, .5, 1.25);
          pointer-events: none;

          &:focus {
            outline: none;
          }

          &:checked {
            box-shadow: inset 0 0 0 6px $base-color;
          }
        }

        span {
          vertical-align: middle;
          display: inline-block;
          line-height: 20px;
          padding: 0 8px;
          @media (max-width: 680px) {
            font-size: 14px;
          }
          @media (max-width: 400px) {
            flex: 1 1 0;
          }
        }
      }
    }
  }

  .purchaseModal {
    width: 700px;

    @media (max-width: 720px) {
      width: calc(100vw - 20px);
    }

    h1 {
      text-align: center;
      font-size: 30px;

      @media (max-width: 400px) {
        font-size: 28px;
      }
    }

    h2 {
      margin-top: 10px;
      font-size: 22px;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }

    h3 {
      margin-top: 15px;
      font-size: 16px;
      @media (max-width: 400px) {
        font-size: 14px;
      }

      span {
        color: $base-color;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: unset;
        }
      }
    }

    .btnWrapper {
      margin-top: 20px;
      padding: 0 !important;
      justify-content: center !important;
    }
  }
}

