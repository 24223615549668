.req {
  div {
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }

    & > span {
      margin-left: 10px;
    }
  }
}
