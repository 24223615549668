.layout {
  display: grid;
  grid-template: "sidebar header" 70px
                 "sidebar main" 1fr / 60px 1fr;

  .main {
    grid-area: main;
    padding: 40px 10px;
    min-height: calc(100vh - 70px);

    @media (max-width: 1200px) {
      padding: 10px;
    }
  }
}
