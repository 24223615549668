@import "src/assets/styles/vars";

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);

  &.absolute {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &.modal {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 0.3rem;
  }

  .ldsRing {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ldsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid transparent;
    border-top-color: $base-color;
    border-radius: 50%;
    animation: lds-ring 1.2s linear infinite;
  }

  .ldsRing div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .ldsRing div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .ldsRing div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.noScroll {
  overflow: hidden;

  & body {
    @media (hover: hover) {
      padding-right: 5px;
    }
    overflow: hidden;
  }
}
